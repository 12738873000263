import React, { useState, useRef } from "react";
import styled from "styled-components";
import Hypher from "hypher";
import german from "hyphenation.de";
const h = new Hypher(german);

const Page = styled.div`
  padding: 20px;
`;
const Wrapper = styled.section`
  display: flex;
  flex-basis: 50%;
  width: 100%;
  margin: 0 -20px;
`;

const Half = styled.article`
  flex: 1 0 50%;
  padding: 0 20px;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
`;

const Pre = styled.pre`
  white-space: normal;

  span {
    font-weight: bold;
    color: #ff0000;
  }
`;

function App() {
  const [text, setText] = useState(
    "Weit hinten, hinter den Wortbergen, fern der Länder Vokalien und Konsonantien leben die Blindtexte. Abgeschieden wohnen sie in Buchstabhausen an der Küste des Semantik, eines großen Sprachozeans."
  );
  const [visible, setVisible] = useState(false);
  const [asHtml, setAsHtml] = useState(false);
  const newText = h.hyphenateText(text);

  const handleInputChange = event => {
    event.persist();
    setText(event.target.value);
  };

  const handleCheckboxChange = event => {
    event.persist();
    setVisible(!visible);
    setAsHtml(false);
  };
  const handleHtmlChange = event => {
    event.persist();
    setAsHtml(!asHtml);
    setVisible(false);
  };

  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);

  function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = asHtml ? newText.replace(/\u00AD/g, `&shy;`) : newText;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  let textToShow = newText;

  if (visible) {
    textToShow = newText.replace(/\u00AD/g, `<span>|</span>`);
  }

  if (asHtml) {
    textToShow = newText.replace(/\u00AD/g, `&shy;`);
  }

  return (
    <Page>
      <h1>Hyphenator</h1>
      <p>
        Gib hier Deinen Text ein und erhalte den Text umgewandelt mit weichen
        Umbrüchen.
      </p>

      <Wrapper>
        <Half>
          <TextArea ols="30" rows="10" onChange={handleInputChange}>
            {text}
          </TextArea>
        </Half>

        <Half>
          {!asHtml && (
            <Pre
              dangerouslySetInnerHTML={{
                __html: textToShow
              }}
            />
          )}
          {
            asHtml &&
            <Pre>
              {textToShow}
            </Pre>
          }

          {/* Logical shortcut for only displaying the
          button if the copy command exists */
          document.queryCommandSupported("copy") && (
            <div>
              <button
                onClick={e => {
                  copyToClipboard(newText);
                }}
              >
                In die Zwischenablage kopieren
              </button>
              {copySuccess}
            </div>
          )}

          <Wrapper>
            <Half>
              <input
                type="checkbox"
                id="visible"
                onChange={handleCheckboxChange}
                checked={visible}
              />
              <label htmlFor="visible">Umbrüche anzeigen</label>
            </Half>

            <Half>
              <input
                type="checkbox"
                id="asHtml"
                onChange={handleHtmlChange}
                checked={asHtml}
              />
              <label htmlFor="asHtml">Als HTML-Code ausgeben</label>
            </Half>
          </Wrapper>
        </Half>
      </Wrapper>
    </Page>
  );
}

export default App;
